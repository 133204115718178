.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    max-width: 600px;
    height: 100%;
    object-fit: cover;
  }
  
  body {
    background: #000;
    color: #000;
  }
  
  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .mySwiper2 {
    height: 80%;
    width: 100%;
  }
  
  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1 !important;
    border: 2px solid #CC61FF;
    border-radius: 10px;
    img {
      border-radius: 8px;
    }
  }

  .mySwiper .swiper-wrapper {
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 8px;
  }

  .swiper-button-prev, .swiper-button-next {
    background: #E7EAEE;
    border-radius: 100px;
    width: 45px;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    font-size: 16px;
    font-weight: 600;
    color: black
  }
  